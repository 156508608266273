<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/ME.jpg" />
    <HelloWorld msg="Meet Justin Bailey" />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    HelloWorld,
  },
};
</script>
