<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
<h2>Tech</h2>
    <p>
      Justin currently works in a fintech company in NYC.<br>
    </p>
    <h2>Content</h2>
    Justin currently is a content creator for Medium and Youtube under <br>
    the name "The Test Lead". There he creates content to help educate  <br>
    people to become the best testers that they can be.
     <ul>
    <b>  <li><a href="https://www.youtube.com/channel/UC7O2ES7xKv35EPtga6OtLHg" target="_blank" rel="noopener">Youtube Page</a></li></b>
    <b>  <li><a href="https://thetestlead.medium.com/" target="_blank" rel="noopener">Medium Page</a></li></b>
    </ul>
        <h2>Reading Material</h2>
    Justin currently has 1 Book out "100 Days of Motivation" ,available on Amazon. <br>
    In 2022 he will be putting out 2 more books in the tech sector, which will <br>
    help detail people to become manual and automated testers for QA.<br>
     <b><a href="https://amzn.to/394Sc9z"> Shop on Amazon </a></b><br>
<br>
<!-- <img alt="JSE" src="../assets/ttt.jpg">-->	
    <h2>Social Media</h2>
    Connect with Justin
    <ul>
      <b><li><a href="https://twitter.com/juss_bailey" target="_blank" rel="noopener">Twitter</a></li>
      <li><a href="https://www.instagram.com/juss_bailey/" target="_blank" rel="noopener">Instagram</a></li></b>
    </ul>
    <h2>Contact Justin</h2>
    You can get into direct contact with Justin via his email address<br>
    JussTheTestLead@gmail.com 
    <br>
    <br>

  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
